<template>
  <div class="pl-lg-9 px-sm-7 pt-sm-5 pt-3 px-3 mb-5 content-surprise">
    <div class="d-flex flex-wrap align-center mb-5">
      <v-text-field
        class="field46"
        v-model="search"
        @keyup="searchQuestion"
        prepend-inner-icon="mdi-magnify"
        placeholder="Search by question"
        hide-details
        outlined
        dense
        required
        color="primary"
        height="46"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn to="/saudi-surprise-create" depressed class="primary rounded" width="136" height="30">
        <span class="text-body-2">Add question</span>
      </v-btn>
    </div>
    <div style="max-height: 69vh; height: 100%" class="view-without-scroll">
      <v-card
        :to="`/saudi-surprise/${n.id}`"
        v-for="n in searchList"
        width="44vw"
        :key="n.id"
        outlined
        class="pa-3 mb-3 rounded-lg"
      >
        <div class="clip">{{ n.question_en }}</div>
        <div class="text-body-2 text--text">
          <span class="text-capitalize">{{ n.question_type ? n.question_type.split('_')[0] : '' }}</span> select: {{ n.answers.length }} |
          Picture {{ n.answer_with_picture ? 'included' : 'not included' }}
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: '',
      searchList: [],
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getTripSSQ');
  },
  methods: {
    searchQuestion() {
      const value = this.search.toString().toLowerCase();
      this.searchList = this.questionList.filter((i) => {
        return Object.keys(i).some((k) => {
          return i[k].toString().toLowerCase().indexOf(value) !== -1;
        });
      });
    },
  },
  watch: {
    questionList(val) {
      val ? (this.searchList = this.questionList) : [];
    },
  },
  computed: {
    questionList() {
      return this.$store.getters.tripsSSQ;
    },
  },
};
</script>
