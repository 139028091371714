<template>
  <div class="settings">
    <div class="text-h5 font-weight-bold">Saudi Surprise</div>
    <v-card class="mt-4" width="100%" outlined>
      <Surprise />
    </v-card>
  </div>
</template>

<script>
import Surprise from '@/components/content/Surprise';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    Surprise,
  },
  data() {
    return {};
  },
  destroyed() {
    this.$store.dispatch('setTripsList');
  },
};
</script>
